<template>
  <ion-page>
    <Header v-if="accessLevel < 3" title="Administração" />
    <Header v-else title="Jogadores" />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle v-if="accessLevel < 3"
            >Jogadores ativos</ion-card-subtitle
          >
          <ion-card-subtitle v-else>Jogadores na plataforma</ion-card-subtitle>
        </ion-card-header>
        <ion-searchbar
          class="nickname-color"
          debounce="1000"
          placeholder="Buscar jogador"
          :value="searchValue"
          @ionChange="searchValue = $event.target.value"
        ></ion-searchbar>
        <ion-card-content>
          <ion-list>
            <div
              class="container-administration"
              v-for="item in allPlayersC.sort((a, b) =>
                a.apelido > b.apelido ? 1 : -1
              )"
              :key="item.cdId"
            >
              <ion-item
                lines="none"
                style="disply: flex; border-bottom: 1px solid #d9d9d9;"
              >
                <Avatar
                  style="z-index: 999999;"
                  class="avatar"
                  width="50"
                  height="50"
                  :imageUrl="item.imagemUrl"
                  eventOn
                  @onClick="setOpenModalPlayerProfile(item.cdId)"
                />
                <ion-label>
                  <h2 class="nickname-color">{{ item.apelido }}</h2>
                </ion-label>
                <ion-icon
                  v-if="item.recuperar && accessLevel == 1"
                  :icon="key"
                  color="warning"
                  style="margin-right: 12px;"
                  @click="setOpenActionSheet(true, item.recuperar)"
                ></ion-icon>
                <div v-else style="display: flex;">
                  <ion-select
                    v-if="accessLevel == 1 && item.nivelAcesso > 1"
                    class="ion-no-padding nickname-color"
                    :value="item.nivelAcesso"
                    @ionChange="onChangePermissionLevel($event, item)"
                  >
                    <ion-select-option value="2">2</ion-select-option>
                    <ion-select-option value="3">3</ion-select-option>
                    <ion-select-option value="4">4</ion-select-option>
                    <ion-select-option value="5">5</ion-select-option>
                  </ion-select>
                  <ion-toggle
                    v-if="accessLevel < 3 && item.nivelAcesso > 1"
                    :color="theme"
                    :checked="item.premium == 1"
                    @ionChange="onChangePremium($event, item.cdId)"
                  ></ion-toggle>
                </div>
              </ion-item>
            </div>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-toast
        :is-open="isOpenToast"
        :color="responseMessage.includes('sucesso') ? 'success' : 'danger'"
        :message="responseMessage"
        :duration="2000"
        @onDidDismiss="setOpenToast(false)"
      >
      </ion-toast>

      <ion-action-sheet
        :is-open="isOpenActionSheet"
        header="Opções"
        :buttons="buttons"
        @onDidDismiss="setOpenActionSheet(false)"
      >
      </ion-action-sheet>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonRefresher,
  IonToast,
  IonActionSheet,
} from "@ionic/vue";
import Header from "@/components/Layout/Header.vue";
import Avatar from "@/components/Layout/Avatar.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";

export default {
  name: "Administration",
  mixins: [requestManager, computedManager],
  components: {
    IonPage,
    IonContent,
    IonRefresher,
    IonToast,
    IonActionSheet,
    Header,
    Avatar,
  },
  data() {
    return {
      isOpenToast: false,
      responseMessage: "",
      players: [],
      playerId: null,
      searchValue: "",
      permissionLevel: {},
      isOpenActionSheet: false,
      buttons: [
        {
          text: "Confirmar solicitação",
          role: "destructive",
          cssClass: "end-game",
          icon: this.checkmarkCircle,
          handler: () => {
            this.confirmRequest(true);
          },
        },
        {
          text: "Cancelar solicitação",
          role: "destructive",
          cssClass: "close",
          icon: this.closeCircle,
          handler: () => {
            this.confirmRequest(false);
          },
        },
        {
          text: "Cancelar",
          icon: this.close,
          cssClass: "close",
          role: "cancel",
          handler: () => {},
        },
      ],
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.common.loading;
      },
      set(pValue) {
        this.$store.commit("common/setLoading", pValue);
      },
    },
    allPlayers() {
      if (this.accessLevel < 3) {
        return this.$store.state.base.allPlayers;
      } else {
        return this.$store.state.base.allPlayers.filter((e) => e.premium > 0);
      }
    },
    allPlayersC() {
      if (this.searchValue) {
        return this.allPlayers.filter((p) => {
          return `${p.nome}${p.apelido}`
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        });
      } else {
        return this.allPlayers;
      }
    },
  },
  async mounted() {
    if (!this.playerInformation || !this.playerInformation.cdId) {
      const playerInformation = localStorage.getItem("playerInformation");
      this.$store.commit(
        "profile/setPlayerInformation",
        JSON.parse(playerInformation)
      );
    }
    if (this.allPlayers.length == 0) await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const response = await this.genericRequest("common/getAllPlayers");
      if (typeof response != "string") {
        this.$store.commit("base/setAllPlayers", response);
      } else {
        this.$store.commit("base/setAllPlayers", []);
      }
      this.loading = false;
    },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalPlayerProfile",
        prop: pPlayerId,
      });

      this.$store.commit("base/addShowModal", "ModalPlayerProfile");
    },
    setOpenToast(pState) {
      this.isOpenToast = pState;
    },
    setOpenActionSheet(pStatus, pPlayerId) {
      this.playerId = pPlayerId;
      this.isOpenActionSheet = pStatus;
    },
    async onChangePermissionLevel(pNivelAcesso, pItem) {
      this.loading = true;
      const response = await this.genericRequest("common/updatePermissions", {
        type: "permission",
        cdIdOwner: this.playerInformation.cdId,
        cdId: pItem.cdId,
        nivelAcesso: pNivelAcesso.detail.value,
      });
      this.responseMessage = response;
      pItem.nivelAcesso = pNivelAcesso.detail.value;
      this.setOpenToast(true);
      this.loading = false;
    },
    async onChangePremium(pPremium, pCdId) {
      this.loading = true;
      const response = await this.genericRequest("common/updatePermissions", {
        type: "premium",
        cdIdOwner: this.playerInformation.cdId,
        cdId: pCdId,
        premium: pPremium.detail.checked ? "1" : "0",
      });
      this.responseMessage = response;
      this.setOpenToast(true);
      this.loading = false;
    },
    async confirmRequest(pConfirm) {
      this.loading = true;
      const response = await this.genericRequest(
        "common/confirmRequestChangePassword",
        {
          cdPlayer: this.playerId,
          confirm: pConfirm ? 1 : 0,
        }
      );
      this.responseMessage = response;
      this.setOpenToast(true);
      this.loading = false;

      if (response.includes("sucesso")) {
        this.init();
      }
    },
    async doRefresh(event) {
      await this.init();
      event.target.complete();
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
  font-size: 25px;
}

ion-label {
  font-size: 18px;
}

ion-fab-button {
  width: 30px;
  height: 30px;
}

ion-icon {
  font-size: 25px;
}

ion-searchbar {
  width: 100% !important;
  --background: white;
  --box-shadow: none !important;
  border-bottom: 1px solid #d9d9d9;
}

ion-list {
  padding: 0;
}

ion-card-content {
  padding: 0;
}

ion-list ion-item {
  padding-top: 0px;
}

ion-item {
  --background: white;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  padding-left: 10px;
  padding-right: 10px;
  --highlight-height: none;
  width: 100%;
  display: flex;
}

ion-select {
  --color: red;
}

.container-administration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
}

.select-text {
  font-weight: bold !important;
}

.avatar {
  margin-right: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
